// src/components/About.js
import React from "react";
import "./About.css"; // Importing styles

const About = () => {
  return (
    <section className="about-section" id="about">
      <div className="about-container">
        <div className="about-text">
          <h2>About Us</h2>
          <p>
            We are a United Global Sourcing dedicated to bringing you the best products from around the world. Our team specializes in sourcing a wide range of high-quality goods to meet your unique needs. Whether you're looking for electronics, textiles, or luxury goods, we are here to provide you with tailored solutions to enhance your business.
          </p>
          <p>
            Our commitment to excellence, attention to detail, and customer-centric approach ensure that we deliver the best sourcing services with speed and reliability. Partner with us to access the highest quality products at competitive prices.
          </p>

          <h3>Our Sourcing Categories</h3>
          <ul>
            <li>
              <strong>Electronics:</strong> From cutting-edge gadgets to essential home and office devices, we connect you with the latest, most reliable electronics tailored to your market needs.
            </li>
            <li>
              <strong>Luxury Goods:</strong> Elevate your offerings with exclusive, high-end products that reflect sophistication, elegance, and unparalleled quality.
            </li>
            <li>
              <strong>Textiles and Apparel:</strong> We specialize in sourcing premium fabrics and stylish clothing, ensuring durability and modern designs that resonate with your customers.
            </li>
            <li>
              <strong>Automotive Supplies:</strong> Access a comprehensive selection of auto parts and accessories to keep your business on the road to success.
            </li>
            <li>
              <strong>Industrial Machinery:</strong> Streamline operations with top-grade industrial equipment and machinery designed for efficiency and longevity.
            </li>
            <li>
              <strong>Furniture and Home Decor:</strong> Discover elegant furniture and decor that merge functionality with aesthetic appeal to enhance living and workspace environments.
            </li>
            <li>
              <strong>Medical Supplies:</strong> Trust us to deliver high-quality medical equipment and consumables that meet international standards of safety and reliability.
            </li>
            <li>
              <strong>Beauty and Personal Care Products:</strong> From skincare essentials to beauty tools, we source products that cater to the ever-growing demand in the personal care market.
            </li>
            <li>
              <strong>Food and Beverage:</strong> Secure a steady supply of quality food products and beverages that align with global trends and local preferences.
            </li>
            <li>
              <strong>Eco-Friendly Products:</strong> Sustainability is at the core of our eco-friendly sourcing solutions, offering products that support greener living and responsible consumption.
            </li>
          </ul>
        </div>
        <div className="about-image">
          <img
            src="https://sourceoneltd.com/storage/2024/03/global-sourcing-company-03.webp"
            alt="About Us"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
